@import '../../../styles/variables.scss';

.content-filter {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 1rem;
    border-bottom: 1px solid $primary-dark;

    &-count {
        font-size: 1.2rem;
        color: black;
    }

    &-app {
        display: flex;
        align-items: center;
        gap: 1rem;
        margin-left: auto;

        input {
            font-size: 1.4rem;
        }

        button {
            border: none;
            font-size: 1.4rem;

            .pi {
                font-size: 1.4rem;
            }
        }
    }
}