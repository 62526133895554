@import './variables.scss';

* {
  box-sizing: border-box;
  font-family: 'SBSansDisplay';
}

h1, h2, h3, h4, h5, h6, a, p {
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
}

body {
  width: 100%;
  display: flex;
  margin: 0;
  padding: 0;
}

*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

*::-webkit-scrollbar-track {
  background-color: $primary-dark;
  border: 1px solid $primary;
}

*::-webkit-scrollbar-thumb {
  background: $primary;
  border-radius: 5px;
}

html {
  font-size: 10px;
  width: 100%;
}

#root {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-wrap: nowrap;

  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.p-tabview-nav {
  background-color: $primary;
}

.p-tabview .p-tabview-nav {
  border-bottom: 1px solid #4b4b4b;
}

.p-tabview .p-tabview-panels {
  padding: 0!important;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  border: none;
  background-color: $primary;
  color: white;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  color: $primary-dark;
}

.p-datepicker:not(.p-datepicker-inline) {
  font-size: 2rem;
}

@media screen and (max-width: 450px) {
  html {
    font-size: 8px;
  }
}


