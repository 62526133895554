@import "../../styles/variables.scss";

.p-dataview-header {
  padding: 0;
  border: none;
}

.p-dataview {
  display: flex;
  flex-direction: column;
  font-size: 1.4rem !important;
  height: 100%;

  .p-inputtext {
    font-size: 14px !important;
  }
}

.grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 1rem;
  padding: 1rem;
}

.dcard {
  width: 40rem;
  box-shadow: 0 0 5px $card;
  border-radius: 0.6rem;

  &-header {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    width: 100%;
    padding: 1rem;
    background-color: $card;
    color: white;
    border-top-left-radius: 0.6rem;
    border-top-right-radius: 0.6rem;



    &-title,
    &-subtitle {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;

      h3 {
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
      }

      &-name {
        background-color: $primary;
        padding: 0.1rem 0.4rem;
        border-radius: 0.4rem;
      }

      &-status {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.1rem 0.4rem;
        border-radius: 0.4rem;
        width: 10rem;

        &-new {
          background-color: #d4af32;
        }

        &-processed {
          background-color: #29b31c;
        }

        &-conversationed {
          background-color: #3e89eb;
        }

        &-ended {
          background-color: #e2850a;
        }

        &-canceled {
          background-color: #e44b30;
        }
      }

      &-dogovor {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.1rem 0.4rem;
        border-radius: 0.4rem;
        background-color: $primary;
        margin-left: auto;
      }

      &-format {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.1rem 0.4rem;
        border-radius: 0.4rem;
        background-color: $primary;
      }
    }
  }

  &-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem 1rem 0.5rem 1rem;

    &-staff {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;
    }

    .p-accordion {
      display: flex;
      flex-direction: column;
      gap: 0.3rem;
    }

    .p-accordion-tab {
      margin-bottom: 0;
    }

    .p-accordion .p-accordion-header-link {
      padding: 0.5rem!important;
    }
    .p-accordion .p-accordion-content {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }
  }

  &-footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    padding: 0.5rem 1rem 1rem;
    border-top: 1px solid #e5e7eb;

    &-delivery, &-master {
      padding: 0.5rem;
      background-color: #f9fafb;
      border: 1px solid #e5e7eb;
      border-radius: 0.6rem;
      color: #6b7280;
      font-weight: bold;
    }
  }
}

.p-tooltip {
  &-text {
    box-shadow: none; 
    font-size: 1.4rem;
    background-color: white;
    border: 2px solid red;
    color: red;
    font-weight: bold;
  }
}

.p-tabview-nav {
  font-size: 2rem;
  padding: 1rem;
  display: flex;
  width: 100%;
  gap: 1rem;
}

.p-tabview-panels {
  font-size: 1.4rem;
}

.profile {
  width: 100%;
  display: flex;
  gap: 2rem;

  &-title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    padding-bottom: .5rem;
  }

  &-column {
    width: 100%;
    height: 100%;
    max-width: 40rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    box-shadow: 0 0 5px $primary;
    border-radius: .6rem;
    padding: 1rem;
  }

  &-row {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;

    input, textarea {
      width: 100%;
      max-width: 25rem;
      font-size: 1.4rem;
    }

    .p-dropdown {
      width: 100%;
      max-width: 25rem;

      .p-dropdown-label {
        font-size: 1.4rem;
      }
    }
  }
}

.table-payment-status {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.4rem;
  border-radius: 0.4rem;
  color: white;
  font-weight: bold;

  &-paid {
    background-color: $done;
  }

  &-notpaid {
    background-color: $cancel;
  }

  &-canceled {
    background-color: $proc;
  }
}

.table-payment-article {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.4rem;
  border-radius: 0.4rem;
  border: 1px solid black;
}

.table-payment-file {
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
}

.company-documents {
  width: 100%;
  display: flex;
  gap: 2rem;

  &-item {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
}

.create-company {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
}

.company-row {
  display: flex;
  align-items: center;
  gap: 2rem;

  &-title {
    width: 14rem;
  }

  input, textarea {
    width: 20rem;
  }

  .p-dropdown {
    width: 100%;
    max-width: 20rem;

    .p-dropdown-label {
      font-size: 1.4rem;
    }
  }
}