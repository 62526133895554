.status-idea {
    display: inline-block;
    width: 15rem;
    padding: 0.4rem;
    border-radius: 0.4rem;
    font-weight: bold;
    color: white;

    &-new {
        background-color: rgb(200, 195, 47);
    }

    &-sogl{
        background-color: rgb(157, 19, 157);
    }

    &-odobr{
        background-color: rgb(52, 52, 195);
    }

    &-done{
        background-color: rgb(8, 130, 8);
    }

    &-work{
        background-color: rgb(17, 210, 68);
    }

    &-noodogr{
        background-color: red;
    }

    &-notdone  {
        background-color: red;
    }  
}

.create-idea {
    padding: 2rem 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    input {
        font-size: 1.7rem;
    }

    .p-dropdown {
        width: 100%;

        .p-dropdown-label {
            font-size: 1.7rem;
        }
    }

    textarea {
        width: 100%;
        height: 20rem;
        font-size: 1.7rem;
    }

    button {
        margin-top: auto;
        font-size: 1.7rem;
    }

}