@import '../../styles/variables.scss';

.payment-table {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .2rem;
    border-radius: .4rem;
    color: white;
    font-weight: bold;

    &-new {
        background-color: $proc;
    }

    &-done {
        background-color: $done;
    }

    &-canceled{
        background-color: $cancel;
    }
}

.p-dropdown-label {
    font-size: 1.4rem;
}

input::file-selector-button {
    font-size: 1.4rem;
    background-color: #7c7c7c;
    border: none;
    cursor: pointer;
    vertical-align: bottom;
    text-align: center;
    color: #ffffff;
    padding: 0.3rem 0.7rem;
    border-radius: 6px;
}