@import "../../styles/variables.scss";

.p-dropdown {
  width: 15rem;
}

.rcard {
  width: 100%;
  max-width: 40rem;
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  box-shadow: 0 0 5px $card;

  &-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 1rem;
    color: white;
    background-color: $card;
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;

    &-title {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      font-size: 2rem;

      &-dogovor {
        font-weight: bold;
        text-decoration: underline;
        cursor: pointer;

        a {
          color: white;
        }
      }

      &-status {
        font-size: 1.4rem;
        margin-left: auto;

        padding: 0.3rem 1rem;
        border-radius: 0.4rem;

        &-done {
          background-color: $done;
        }

        &-not-done {
          background-color: $cancel;
        }
      }
    }

    &-date {
      display: flex;
      gap: 0.4rem;
      align-items: center;
      width: 100%;
      font-size: 1.4rem;

      &-name {
        text-decoration: underline;
      }

      &-buttons {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 0.3rem;
        margin-left: auto;

        button {
          width: 2.2rem;
          padding: 0.4rem 0;
        }
      }
    }
  }

  &-body {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 1rem;

    &-sum {
      font-size: 1.8rem;

      &-num {
        font-weight: bold;
        text-decoration: underline;
        color: $cancel;
      }
    }

    &-comment {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      &-title {
        font-size: 1.8rem;
      }

      &-text {
        font-size: 1.4rem;
        font-style: italic;
        text-decoration: underline;
      }
    }
  }

  &-footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 1rem;
    border-top: 1px solid $card;
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;

    &-date {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      width: 100%;
      font-size: 1.4rem;

      &-days {
        margin-left: auto;

        &-count {
          padding: 0.2rem 0.6rem;
          border-radius: 0.4rem;
          color: white;
          font-weight: 700;

          &-green {
            background-color: $done;
          }

          &-orange {
            background-color: $proc;
          }

          &-red {
            background-color: $cancel;
          }
        }
      }
    }
  }
}

.count-status {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.3rem;
  background-color: $primary;
  padding: 0.2rem 0.6rem;
  border-radius: 0.4rem;
  color: white;
  font-weight: 700;
}

.p-toast {
  left: 42% !important;
}

.p-toast .p-toast-message {
  width: 55rem;
}
.p-toast-message-content {
  font-size: 1.4rem;
}

.create-refund {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  input {
    font-size: 1.7rem;
  }

  textarea {
    width: 100%;
    height: 20rem;
    font-size: 1.7rem;
  }

  button {
    margin-top: auto;
    font-size: 1.7rem;
  }
}

.p-dialog-title {
  font-size: 2rem;
}

.p-confirm-dialog-message {
  font-size: 2rem;
}

.p-dialog-footer {
  button {
    font-size: 2rem;
  }
}