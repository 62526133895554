@import './variables.scss';

.login {
    width: 100%;
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(45deg, $primary, $primary-dark);
  
    .login-form {
      position: relative;
      margin: 1rem 0;
      max-width: 100%;
      box-shadow: 0px 15px 25px -15px $primary;
      width: 380px;
      border-radius: 0 0 10px 10px;
    }
  
    .login-form-title {
      font-weight: 500;
      font-style: italic;
      background: $primary-dark;
      color: #fff;
      text-align: center;
      margin: 0;
      padding: 1.5rem 1rem;
      font-size: 2rem;
      border-radius: 10px 10px 0 0;
    }
  
    .login-form-content {
      padding: 2rem;
      background: #fff;
      border-radius: 0 0 10px 10px;
      display: flex;
      gap: 2rem;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 1.4rem;

      input {
        width: 20rem;
        padding: 1rem 0.75rem;
        font-size: 1.4rem;
      }
      
        .p-float-label:has(input:focus) label,
        .p-float-label:has(input.p-filled) label,
        .p-float-label:has(input:-webkit-autofill) label,
        .p-float-label:has(textarea:focus) label,
        .p-float-label:has(textarea.p-filled) label,
        .p-float-label:has(.p-inputwrapper-focus) label,
        .p-float-label:has(.p-inputwrapper-filled) label {
            top: -1rem;
          }
    }
  
    .login-form-row {
      position: relative;
    }

    .login-btn {
      background: $primary-dark;
      padding: 12px 45px;
      box-shadow: 0 2px 5px -2px $primary, inset 0 -1px $primary;
      position: relative;
      border: none;
      cursor: pointer;
      text-align: center;
    }
  
    .login-form-error {
      font-size: 1.5rem;
      color: red;
    }

    
  }
  