.create-order {
    display: flex;
    flex-direction: column;
    gap: 1rem;


    textarea {
        width: 100%;
        height: 14rem;
        font-size: 1.6rem;
    }

    button {
        font-size: 1.8rem;
    }
}

.createOrder {
    position: relative;
    
    &-footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 2rem;
        width: 100%;
        background-color: white;
        padding: 1rem 0;

        button {
            font-size: 2rem;
        }
    }
}

.createOrder .p-sidebar-header {
    border-bottom: 1px solid black;
    margin-bottom: 1rem;
}

.create-order-subtitle {
    color: blue;
    margin-bottom: 1rem;
}

.order-inputs {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 5%;

    &-row {
        width: 45%;
        display: flex;
        flex-direction: column;
        padding: 0.5rem 0;
        gap: 0.5rem;

        .order-inputs-title {
            text-decoration: underline;
            font-size: 1.7rem;
        }

        input {
            font-size: 1.6rem;
            width: 100%;
        }
    }
}