.field--tables {
    width: 100%;
    display: flex;
    justify-content: space-around;
    padding: 1rem;
}

.manager_view {
    padding: 1rem;
}

.manager_view:not(:last-child) {
    border-bottom: 1px dotted grey;
}

.grafics {
    width: 100%;
    display: flex;
    gap: 1rem;
    padding: 1rem;

    &_vhod {
        width: 50%;
        box-shadow: 0 0 5px black;
        padding: 1rem;
        border-radius: 1rem;
        display: flex;
        gap: 1rem;
    }

    &_ishod {
        width: 50%;
        box-shadow: 0 0 5px black;
        padding: 1rem;
        border-radius: 1rem;
        display: flex;
        gap: 1rem;
    }

    &_info {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        font-size: 1.5rem;
    }
}