@import '../../../styles/variables.scss';

.date {
    display: flex;
    align-items: center;
    height: 5rem;
    gap: 1rem;
    font-size: 1.4rem;
    color: white;
    font-weight: bold;
    padding: 1rem;
    border-bottom: 1px solid $primary-dark;
    margin-bottom: 1rem;

    button {
      margin-left: auto;
      padding: 0.5rem;
    }

    .pi-calculator:before {
      font-size: 1.7rem;
    }
  }