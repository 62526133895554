@import '../../styles/variables.scss';

.manager-status-table {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .2rem;
    border-radius: .4rem;
    color: white;
    font-weight: bold;

    &-new {
        background-color: $proc;
    }

    &-done {
        background-color: $done;
    }

    &-canceled{
        background-color: $cancel;
    }
}

.create {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    button {
        font-size: 1.4rem;
        background-color: $primary;
        border: none;
    }
}

.create-row {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    label {
        font-weight: bold;
    }

    input, textarea {
        font-size: 1.4rem;
    }

    textarea {
        max-width: 100%;
        min-width: 100%;
        height: 20rem;
    }


}

.tables {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;

    &-top {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }
}