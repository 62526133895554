@import "../../../styles/variables.scss";

.sidebar {
  display: flex;
  flex-direction: column;
  flex: 0 0 23rem;
  background-color: $primary;
  border-right: 1px solid $primary-dark;
  font-size: 1.4rem;

  a {
    color: white;
    padding: 0.7rem;
  }

  .active {
    background-color: $primary-dark;
  }

  &-sites {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: auto;
    // position: sticky;
    // bottom: 1rem;
  }

  &-links {
    display: flex;
    flex-direction: column;
    width: 100%;
    // position: sticky;
    // top: 1rem;
  }

  &-profile {
    display: flex;
    align-items: center;
    gap: 1rem;
    width: 100%;
    // position: sticky;
    // bottom: 1rem;
    padding: 0.7rem;
    color: white;
    border-top: 1px solid $primary-dark;
    margin-top: 1rem;

    button {
      background-color: $primary-dark;
      border: none;
      margin-left: auto;
    }

    a {
      border-radius: 0.4rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .role {
    color: white;
    margin: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: grey;
    padding: 0.5rem;
    border-radius: 1rem;
    font-size: 1.3rem;
    font-weight: 700;
  }

  .p-panelmenu .p-panelmenu-header .p-panelmenu-header-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: $primary;
    font-size: 1.6rem;
    border: none;
    font-weight: bold;
  }

  .p-panelmenu-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background: $primary;
    border: none;
  }

  .p-menuitem-content {
    display: flex;
    width: 100%;

    a {
      width: 100%;
      padding: 0.75rem 2.2rem;

      &:hover {
        background: $primary-dark;
      }
    }
  }

  .p-panelmenu
    .p-panelmenu-content
    .p-menuitem
    > .p-menuitem-content
    .p-menuitem-link
    .p-menuitem-text {
    color: white;
    font-size: 1.4rem;
  }

  .p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content {
    color: #4b5563;
    background: none;
  }
}

@media screen and (max-width: 450px) {
  .sidebar {
    display: none;
  }
}
