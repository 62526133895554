.p-datatable {
    display: flex;
    flex-direction: column;
    font-size: 1.4rem!important;
    height: 100%;

    
    .p-inputtext {
        font-size: 14px!important;
    }
}

.p-datatable-header{
    padding: 0;
    border: none;
}

.create-comment {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    input {
        font-size: 1.7rem;
    }

    .p-dropdown {
        width: 100%;

        .p-dropdown-label {
            font-size: 1.7rem;
        }
    }

    textarea {
        width: 100%;
        height: 20rem;
        font-size: 1.7rem;
    }

    button {
        margin-top: auto;
        font-size: 1.7rem;
    }

}