.carmaster {
    padding: 0.3rem;
    border-radius: 0.4rem;
    font-weight: 700;
    color: white;

    &-new {
        background-color: rgb(189, 189, 52);
    }

    &-service {
        background-color: rgb(95, 95, 176);
    }

    &-done {
        background-color: green;
    }

    &-trans {
        background-color: rgb(191, 138, 39);
    }

    &-cancel {
        background-color: rgb(195, 52, 52);
    }

    &-service-to {
        background-color: rgb(125, 9, 125);
    }

    &-service-from {
        background-color: brown;
    }
}

.card {
    position: relative;
    display: flex;
    width: 100%;
    padding: 1rem;
    box-shadow: 0 0 2px black;
    border-radius: 0.6rem;

    &-info {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        font-size: 1.9rem;
    }

    &-phone {
        color: blue;
        text-decoration: underline;
    }

    &-vin {
        font-weight: 700;
    }

    &-comment {
        width: 100%;
        border-top: 1px solid black;
        padding-top: 1rem;
    }

    &-btns {
        position: absolute;
        width: 15rem;
        top: 1rem;
        right: 1rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        button {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1.3rem;
        }
    }

    &-service {
        background-color: rgb(156, 156, 234);
    }

    &-done {
        background-color: rgb(82, 225, 82);
    }

    &-trans {
        background-color: rgb(191, 138, 39);
    }

    &-cancel {
        background-color: rgb(195, 52, 52);
    }


}

