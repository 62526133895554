@import './variables.scss';

.content {
    width: 100%;
    height: 100%;
    display: flex;
    flex: 1;

    .field {
        width: 100%;
        display: flex;
        flex-direction: column;
        overflow: auto;

        &-cards {
            width: 100%;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            gap: 1rem;
            padding: 1rem;
        }

        .p-paginator {
            margin-top: auto;
        }
    }
}

.p-sidebar {
    width: 35rem;
    font-size: 1.4rem;
}