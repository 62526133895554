@import '../../../styles/variables.scss';

.content-top {
    width: 100%;
    height: 5rem;
    display: flex;
    // flex-direction: column;
    align-items: center;
    padding: 1rem;
    background-color: $primary;
    border-bottom: 1px solid $primary-dark;

    &-header {
        width: 100%;
        height: 3rem;
        display: flex;
        align-items: center;
    }

    &-title {
        color: white;
        font-size: 1.7rem;
        text-transform: uppercase;
        font-weight: 700;
    }

    &-app {
        display: flex;
        gap: 1rem;
        margin-left: auto;

        input {
            width: 15rem;
            font-size: 1.4rem;
        }

        button {
            background-color: $primary-dark;
            border: none;
            font-size: 1.4rem;
            overflow: initial;
        }

        .p-button.p-button-icon-only {
            width: 3rem!important;
        }
    }
}

.burger-button {
    display: none;

    @media screen and (max-width: 450px) {
        display: flex;
    }
}

.sidebar-burger {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 0 0 23rem;
    font-size: 1.4rem;
  
    a {
      color: $primary-dark;
      padding: 0.7rem;
    }
  
    .active {
      background-color: $primary-dark;
      color: white;
      border-radius: 0.4rem;
    }
  
    &-sites {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: auto;
      color: $primary-dark;
    }
  
    &-links {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  
    &-profile {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;
      width: 100%;
      padding: 0.7rem;
      color: $primary-dark;
      border-top: 1px solid $primary-dark;
      margin-top: 1rem;
  
      button {
        background-color: $primary-dark;
        border: none;
      }
    }
  
    .role {
      color: $primary-dark;
      margin: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: grey;
      padding: 0.5rem;
      border-radius: 1rem;
      font-size: 1.3rem;
      font-weight: 700;
    }
  
    .p-panelmenu .p-panelmenu-header .p-panelmenu-header-content {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    //   background-color: $primary;
      font-size: 1.6rem;
      border: none;
      font-weight: bold;
    }
  
    .p-panelmenu-content {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    //   background: $primary;
      border: none;
    }
  
    .p-menuitem-content {
      display: flex;
      width: 100%;
  
      a {
        width: 100%;
        padding: 0.75rem 2.2rem;
  
        &:hover {
          background: $primary-dark;
        }
      }
    }
  
    .p-panelmenu
      .p-panelmenu-content
      .p-menuitem
      > .p-menuitem-content
      .p-menuitem-link
      .p-menuitem-text {
      color: $primary-dark;
      font-size: 1.4rem;
    }
  
    .p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content {
      color: #4b5563;
      background: none;
    }
}